import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';

export type Theme = 'light' | 'dark';
export type Sidebar = 'open' | 'close';

interface ThemeContextType {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  sidebar: Sidebar;
  setSidebar: (sidebar: Sidebar) => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const initialTheme = (localStorage.getItem('theme') as Theme) || 'dark';
  const [theme, setTheme] = useState<Theme>(initialTheme);
  const initialSidebar = (localStorage.getItem('sidebar') as Sidebar) || 'open';
  const [sidebar, setSidebar] = useState<Sidebar>(initialSidebar);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme') as Theme;
    if (!storedTheme) {
      localStorage.setItem('theme', theme); 
    } else if (storedTheme !== theme) {
      setTheme(storedTheme);
    }
    // document.body.className = theme
  }, [theme]);

  const updateTheme = (newTheme: Theme) => {
    localStorage.setItem('theme', newTheme);
    setTheme(newTheme);
  };

  useEffect(() => {
    const storedSidebar = localStorage.getItem('sidebar') as Sidebar;
    if (!storedSidebar) {
      localStorage.setItem('sidebar', sidebar); 
    } else if (storedSidebar !== sidebar) {
      setSidebar(storedSidebar);
    }
  }, [sidebar]);

  const updateSidebar = (newSidebar: Sidebar) => {
    localStorage.setItem('sidebar', newSidebar);
    setSidebar(newSidebar);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme: updateTheme, sidebar, setSidebar: updateSidebar }}>
      {children}
    </ThemeContext.Provider>
  );
};
