import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import { getStoredValue } from '../hooks/useStorage';

export type languageCodes = 'en' | 'de' | 'es' | 'no';

export interface LanguageModel {
    code: languageCodes,
    name: string,
    flag?: string,
    momentCode: string
}
export const languages: LanguageModel[] = [
    { code: 'en', name: 'English', momentCode: 'en-gb' },
    { code: 'de', name: 'German', momentCode: 'de' },
    { code: 'es', name: 'Spanish', momentCode: 'es' },
    { code: 'no', name: 'Norwegian', momentCode: 'nb' }
];
// 

const loadTranslationFiles = async () => {
    const resources: Record<string, any> = {};

    for (const lang of languages) {
        try {
            const userDetails: string = getStoredValue('user') ?? '{}';
            const clientId: string = JSON.parse(userDetails)?.client_id ?? '';

            // Attempt to load the primary translation file
            const primaryTranslations = await import(`../../languages/${clientId}/${lang.code}.json`).then(module => module.default).catch(() => ({}));
            
            // Attempt to load the default translation file as a fallback
            const defaultTranslations = await import(`../../languages/defaultFallback/${lang.code}.json`).then(module => module.default).catch(() => ({}));

            // Merge the primary translations with the default translations
            resources[lang.code] = {
                translation: {
                    ...defaultTranslations, // Default translations are loaded first
                    ...primaryTranslations, // Primary translations will overwrite defaults if they exist
                },
            };
        } catch (error) {
            console.error(`Failed to load translation for language: ${lang.code}`, error);
        }
    }

    return resources;
};
const initializeI18Next = async () => {
    const resources = await loadTranslationFiles();
    const defaultLanguage = getStoredValue('language') ?? 'en';

    i18n
        .use(initReactI18next)
        .init({
            debug: false, // Enable this in development
            resources,
            lng: defaultLanguage, // Default language
            fallbackLng: 'en', // Fallback language
            interpolation: {
                escapeValue: false, // React already escapes from XSS
            },
        });
};

export default initializeI18Next;