import { getStoredValue } from "./hooks/useStorage";
import PermissionRules, { accessLevelTypes, PermissionModel } from "./config/permissions";
import { userRoleTypes } from "./enums/enums";

export const userRole: userRoleTypes = getStoredValue('user') ? JSON.parse(getStoredValue('user') ?? '')?.role : null;
// const permissions: string[] = getStoredValue('permissions') ? Object.values(JSON.parse(getStoredValue('permissions') ?? '')) : [];
const permissions: PermissionModel[] = PermissionRules;

export const checkPermission = (key: string) => {
    // const getAccessLevelAction = (key: string) => appRoutes.find((item: AppRoutesModel) => { return item.routeName === key})?.action ?? '';
    // const getAccessedPage = (key: string) => appRoutes.find((item: AppRoutesModel) => { return item.routeName === key})?.page ?? '';

    // return ([...permissions].filter((item: string) => {
    //     return (getAccessedPage(key) && item?.indexOf(`${getAccessLevelAction(key)}_${getAccessedPage(key)}`) > -1)
    //     // return ((getAccessedPage(key) && (item?.indexOf(getAccessedPage(key)) > -1)) && (getAccessLevelAction(key) && (item?.indexOf(getAccessLevelAction(key)) > -1)))
    // })?.length > 0) || userRole === 'superuser';
    return true
}

export const hasAccess = (path: string): boolean => {
    const permission = permissions?.find((el: PermissionModel) => {
        return el.pathName === path
    })
    const isAllowed: boolean = permission ? (permission[userRole] !== null && permission[userRole] !== 'full_self') : true;

    return isAllowed;
}

export const editAllowed = (path: string): boolean => {
    const permission = permissions?.find((el: PermissionModel) => {
        return el.pathName === path
    })
    const isAllowed: boolean = permission ? (permission[userRole] === 'full' && permission[userRole] !== 'full_self') : false;

    return isAllowed;
}

export const viewOnlyAllowed = (path: string): boolean => {
    const permission = permissions?.find((el: PermissionModel) => {
        return el.pathName === path
    })
    const isAllowed: boolean = permission ? permission[userRole] === 'get_only' : false;

    return isAllowed;
}

export const selfAccess = (path: string): boolean => {
    const permission = permissions?.find((el: PermissionModel) => {
        return el.pathName === path
    })
    const isAllowed: boolean = permission ? permission[userRole] === 'full_self' : false;

    return isAllowed;
}

export const accessLevel = (path: string): accessLevelTypes => {
    const permission = permissions?.find((el: PermissionModel) => {
        return el.pathName === path
    })
    const level: accessLevelTypes = permission ? permission[userRole] : null;

    return level;
}